import { Route, Routes } from "react-router-dom";
import UnthorizedRoutes from "./routes/UnthorizedRoutes";
function App() {
return (
  
  <UnthorizedRoutes/>
)

}

export default App;
